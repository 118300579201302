<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="inputModel.createNew"
        ><v-icon>mdi-pencil-plus-outline</v-icon
        >{{ allowedAction(constants.cafereoActions) ? "取引先" : "店舗" }}登録</span
      >
      <span class="headline" v-if="!inputModel.createNew"
        ><v-icon>mdi-pencil-outline</v-icon
        >{{ allowedAction(constants.cafereoActions) ? "取引先" : "取引先" }}編集</span
      >
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="customerForm" lazy-validation>
        <v-container style="max-width: inherit">
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="6" sm="6">
              <corporation-field
                :rules="[rules.required]"
                :group="vendorCorporationType"
                :code="updateModel.corporateCode"
                v-model="updateModel.corporateCode"
                :readonly="!inputModel.createNew"
              ></corporation-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="取引先名"
                :rules="[rules.required, rules.maxLength(150)]"
                v-model="updateModel.customerName"
                filled
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <v-text-field
                label="郵便番号"
                :rules="[rules.zipCode]"
                v-model="updateModel.zipCode"
                :error-messages="errors"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field
                label="都道府県"
                v-model="updateModel.prefecture"
                filled
                dense
                :rules="[rules.required, rules.maxLength(4)]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="所在地（住所）"
                v-model="updateModel.locationAddress"
                filled
                dense
                :rules="[rules.required, rules.maxLength(150)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="所在地（ビル名）"
                v-model="updateModel.locationBuilding"
                filled
                dense
                :rules="[rules.maxLength(150)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <v-text-field label="電話番号" :rules="[rules.phoneNo]" v-model="updateModel.telNumber" filled dense>
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-text-field label="FAX番号" :rules="[rules.phoneNo]" v-model="updateModel.faxNumber" filled dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="担当者"
                v-model="updateModel.customerStaff"
                filled
                dense
                :rules="[rules.maxLength(150)]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <v-text-field
                label="社内発注上限金額"
                v-model.number="updateModel.inCompanyOrderMaxAmount"
                filled
                :rules="[rules.isNumber, rules.maxLength(13)]"
                @change="updateModel.inCompanyOrderMaxAmount = stringToNumber($event)"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="6">
              <v-select
                :items="customerStatus"
                :label="'ステータス'"
                dense
                filled
                v-model="updateModel.customerStatus"
                :rules="[rules.required]"
                attach
                :readonly="!allowedAction(constants.cafereoActions)"
              ></v-select>
            </v-col>
          </v-row>
          <template v-if="allowedAction('C070302', 'C070304')">
            <v-row dense>
              <v-col cols="6" sm="6">
                <v-select
                  :items="paymentTermsClosingDate"
                  label="支払条件締日"
                  v-model="updateModel.paymentTermsClosingDate"
                  dense
                  clearable
                  filled
                  attach
                  :rules="[rules.required]"
                ></v-select>
              </v-col>
              <v-col cols="6" sm="6">
                <v-select
                  :items="paymentTermsPaymentDate"
                  label="支払条件支払日"
                  dense
                  filled
                  clearable
                  v-model="updateModel.paymentTermsPaymentDate"
                  :rules="[rules.required]"
                  attach
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" sm="6">
                <dp-date-picker
                  type="date"
                  label="取引開始年月日"
                  v-model="updateModel.transactionStartDate"
                  inset
                  dense
                  :rules="[rules.required]"
                ></dp-date-picker>
              </v-col>
              <v-col cols="6" sm="6">
                <v-select
                  :items="shipmentDayOfWeek"
                  label="出荷曜日指定"
                  dense
                  filled
                  v-model="updateModel.shipmentDayOfWeek"
                  multiple
                  attach
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" sm="4">
                <v-switch
                  label="オリコン指定"
                  v-model="updateModel.useOricon"
                  inset
                  dense
                  :rules="[rules.required]"
                ></v-switch>
              </v-col>
              <v-col cols="6" sm="4">
                <v-switch
                  label="フォーキャスト可否"
                  v-model="updateModel.forecast"
                  inset
                  dense
                  :rules="[rules.required]"
                ></v-switch>
              </v-col>
              <v-col cols="6" sm="4">
                <v-switch
                  label="自動出荷希望"
                  v-model="updateModel.autoShipRequest"
                  inset
                  dense
                  :rules="[rules.required]"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="備考"
                  v-model="updateModel.remarks"
                  filled
                  dense
                  :rules="[rules.maxLength(100)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="倉庫備考"
                  v-model="updateModel.warehouseRemarks"
                  filled
                  dense
                  :rules="[rules.maxLength(150)]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" sm="6">
                <v-switch
                  label="請求書発行可否"
                  v-model="updateModel.invoiceApproval"
                  inset
                  dense
                  :rules="[rules.required]"
                ></v-switch>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field
                  label="請求先〒"
                  :rules="[rules.zipCode]"
                  v-model="updateModel.invoiceZipCode"
                  filled
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="請求先住所"
                  v-model="updateModel.invoiceAddress"
                  filled
                  dense
                  :rules="[rules.maxLength(150)]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" sm="6">
                <v-text-field
                  label="請求先TEL"
                  :rules="[rules.phoneNo]"
                  v-model="updateModel.invoicePhoneNumber"
                  filled
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-text-field
                  label="請求担当者"
                  v-model="updateModel.invoiceStaff"
                  filled
                  dense
                  :rules="[rules.maxLength(150)]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="12">
                <v-text-field
                  label="請求書宛名"
                  v-model="updateModel.invoiceMailingAddress"
                  filled
                  dense
                  :rules="[rules.maxLength(150)]"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </template>
        </v-container>
        <v-divider></v-divider>
        <template v-if="allowedAction('C070302', 'C070304')">
          <v-card-subtitle>付加情報</v-card-subtitle>
          <v-container style="max-width: inherit">
            <v-row dense>
              <v-col cols="6" sm="6">
                <v-text-field label="担当部署" v-model="updateModel.salesDepartment" filled dense disabled>
                </v-text-field>
              </v-col>
              <v-col cols="6" sm="6">
                <cafereo-user-field
                  ref="salesStaff"
                  label="営業担当"
                  :value="updateModel.ourSalesStaff"
                  v-model="updateModel.ourSalesStaff"
                  :rules="[rules.required]"
                ></cafereo-user-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="6" sm="6">
                <cafereo-users-field
                  ref="salesAssistant"
                  label="担当AS"
                  :value="updateModel.assistant"
                  v-model="updateModel.assistant"
                ></cafereo-users-field>
              </v-col>
              <v-col cols="6" sm="6">
                <v-select
                  :items="janDisplay"
                  :label="'JAN表示'"
                  dense
                  filled
                  v-model="updateModel.janDisplay"
                  :rules="[rules.required]"
                  attach
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="$emit('onDialogClose')">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="inputModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!inputModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import ProductListLayouts from "../../consts/ProductListLayouts";
import { comma } from "../../filter/NumberFilter";
import CorporationTypes from "../../consts/CorporationTypes";
import CustomerStatus from "../../consts/CustomerStatus";
import JanDisplay from "../../consts/JanDisplay";
import Validation from "../../libs/validation";
import { statuses as ApiStatus } from "../../libs/api-client";
import CorporationField from "../../components/common/fields/CorporationField.vue";
import { VendorPaymentTermsClosingDate, MakerPaymentTermsClosingDate } from "../../consts/PaymentTermsClosingDate";
import { VendorPaymentTermsPaymentDate, MakerPaymentTermsPaymentDate } from "../../consts/PaymentTermsPaymentDate";
import ShipmentDayOfWeek from "../../consts/ShipmentDayOfWeek";
import CafereoUserField from "../../components/common/fields/CafereoUserField.vue";
import CafereoUsersField from "../../components/common/fields/CafereoUsersField.vue";
import ConvertUtils from "../../utils/ConvertUtils";

export default {
  name: "CustomerEntry",
  props: ["inputModel"],
  components: {
    CorporationField,
    CafereoUserField,
    CafereoUsersField,
  },
  filters: {
    comma,
  },
  data() {
    return {
      stringToNumber: ConvertUtils.stringToNumber,
      errors: "",
      productListLayouts: ProductListLayouts.all(),
      updateModel: null,
      constants: {
        cafereoActions: ["C070302", "C070304"],
      },
      vendorCorporationType: CorporationTypes.VENDOR,
      paymentTermsClosingDate: VendorPaymentTermsClosingDate.all(),
      paymentTermsPaymentDate: VendorPaymentTermsPaymentDate.all(),
      mpaymentTermsClosingDate: MakerPaymentTermsClosingDate.all(),
      mpaymentTermsPaymentDate: MakerPaymentTermsPaymentDate.all(),
      shipmentDayOfWeek: ShipmentDayOfWeek.all(),
      customerStatus: CustomerStatus.all(),
      janDisplay: JanDisplay.all(),
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
        isNumber: Validation.isNumber,
      },
    };
  },
  beforeMount() {
    this.initUpdateModel(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.initUpdateModel(inputModel);
    },
    "updateModel.ourSalesStaff"() {
      if (this.$refs?.salesStaff?.current != null) {
        this.updateModel.salesDepartment = this.$refs.salesStaff.current?.department;
      }
    },
    async "updateModel.zipCode"(value) {
      this.errors = await this.validDeliveryZipArea(value);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    initUpdateModel(inputModel) {
      this.updateModel = {
        createNew: inputModel.createNew,
        corporateCode: inputModel.corporateCode,
        customerCode: inputModel.customerCode,
        customerName: inputModel.customerName,
        zipCode: inputModel.zipCode,
        prefecture: inputModel.prefecture,
        locationAddress: inputModel.locationAddress,
        locationBuilding: inputModel.locationBuilding,
        telNumber: inputModel.telNumber,
        faxNumber: inputModel.faxNumber,
        customerStaff: inputModel.customerStaff,
        inCompanyOrderMaxAmount: inputModel.inCompanyOrderMaxAmount,
        customerStatus: inputModel.customerStatus,
        paymentTermsClosingDate: inputModel.paymentTermsClosingDate,
        paymentTermsPaymentDate: inputModel.paymentTermsPaymentDate,
        transactionStartDate: inputModel.transactionStartDate,
        shipmentDayOfWeek: inputModel.shipmentDayOfWeek,
        useOricon: inputModel.useOricon,
        forecast: inputModel.forecast,
        autoShipRequest: inputModel.autoShipRequest,
        remarks: inputModel.remarks,
        warehouseRemarks: inputModel.warehouseRemarks,
        invoiceApproval: inputModel.invoiceApproval,
        invoiceZipCode: inputModel.invoiceZipCode,
        invoiceAddress: inputModel.invoiceAddress,
        invoicePhoneNumber: inputModel.invoicePhoneNumber,
        invoiceStaff: inputModel.invoiceStaff,
        invoiceMailingAddress: inputModel.invoiceMailingAddress,
        salesDepartment: inputModel.salesDepartment,
        ourSalesStaff: inputModel.ourSalesStaff,
        assistant: inputModel.assistant,
        janDisplay: inputModel.janDisplay,
        updateDatetime: inputModel.updateDatetime,
      };
      if (this.$refs.customerForm) {
        this.$refs.customerForm.resetValidation();
      }
    },
    reset() {
      this.initUpdateModel(this.updateModel);
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("customer/create", { ...this.updateModel });
          if (ApiStatus.isSystemError(result.data?.header)) {
            return this.redirectError();
          }
          this.loadingOff();
          this.$emit("onEntrySubmit", result.data.contents.customer);
        } catch (error) {
          console.error("CustomerUpdate::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("customer/update", { ...this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.$emit("onEditSubmit", result.data.contents.customer);
              this.reset();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: this.allowedAction(this.constants.cafereoActions) ? "取引先" : "店舗",
                text: result.data.header.messages[this.updateModel.customerCode].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("CustomerUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    validate() {
      let isValid = this.$refs.customerForm.validate();
      if (!isValid || this.errors !== "") {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },
    async validDeliveryZipArea(value) {
      if (!value) return "";
      let res = await this.$store.dispatch("deliveryArea/isZipCode", {
        zipCode: value,
      });

      if (!res.data.contents.isZipCode) {
        return "入力された郵便番号がありません。";
      }
      return "";
    },
  },
};
</script>
