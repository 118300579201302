<template>
  <v-card>
    <v-card-title>
      <span class="headline" v-if="inputModel.createNew"><v-icon>mdi-pencil-plus-outline</v-icon>配送先登録</span>
      <span class="headline" v-if="!inputModel.createNew"><v-icon>mdi-pencil-outline</v-icon>配送先編集</span>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-form ref="deliveryForm" v-model="validEntryForm" lazy-validation>
        <v-container style="max-width: inherit">
          <v-row dense>
            <v-col cols="6" sm="6" v-if="allowedAction(constants.cafereoActions)">
              <corporation-field
                ref="corporations"
                :group="makerCorporationType"
                :group2="vendorCorporationType"
                :value="updateModel.corporationCd"
                v-model="updateModel.corporationCd"
                :rules="[rules.required]"
              ></corporation-field>
            </v-col>
            <v-col cols="6" sm="6" v-if="corporationType === makerCorporationType">
              <supplier-field
                v-model="updateModel.groupCode"
                :corporate="updateModel.corporationCd"
                :label="allowedAction(constants.cafereoActions) ? '取引先名' : '仕入先'"
                :rules="[rules.required]"
              ></supplier-field>
            </v-col>
            <v-col cols="6" sm="6" v-if="corporationType === vendorCorporationType">
              <customer-field
                :corporate="updateModel.corporationCd"
                :code="updateModel.groupCode"
                :label="allowedAction(constants.cafereoActions) ? '取引先名' : '店舗名'"
                v-model="updateModel.groupCode"
                :rules="[rules.required]"
                :disabled="vendorDisabledRule"
              ></customer-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="配送先〒"
                v-model="updateModel.deliveryZipCode"
                :rules="[rules.required, rules.zipCode]"
                :error-messages="errors"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="届先法人名"
                v-model="updateModel.delivery1"
                filled
                dense
                :rules="[rules.maxByteLengthSjis(50)]"
                :readonly="updateModel.transferWarehouses"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label=" 届先名称"
                v-model="updateModel.delivery2"
                filled
                dense
                :rules="[rules.required, rules.maxByteLengthSjis(30)]"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="12">
              <v-text-field
                label="配送先住所"
                v-model="updateModel.deliveryAddress"
                :rules="[rules.required, rules.maxLength(150)]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="6">
              <v-text-field
                label="配送先TEL"
                v-model="updateModel.deliveryPhoneNumber"
                :rules="[rules.phoneNo]"
                filled
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6" sm="6">
              <v-select
                :items="deliveryStatus"
                :label="'ステータス'"
                dense
                filled
                v-model="updateModel.deliveryStatus"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6" v-if="allowedAction(constants.cafereoActions)">
              <v-switch
                label="ルート配送"
                inset
                dense
                v-model="updateModel.routeDelivery"
                :rules="[rules.required]"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row dense v-if="allowedAction(constants.cafereoActions)">
            <v-col cols="6" sm="6">
              <v-select
                :items="slipIssuanceType"
                :label="'伝票発行区分'"
                dense
                filled
                v-model="updateModel.slipIssuance_type"
                :rules="[rules.required]"
                attach
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6">
              <v-switch
                label="倉庫間移動出荷"
                inset
                dense
                v-model="updateModel.transferWarehouses"
                :rules="[rules.required]"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="secondary" @click="onClose">キャンセル</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="onCreateClick" v-if="inputModel.createNew">登録</v-btn>
      <v-btn color="primary" @click="onUpdateClick" v-if="!inputModel.createNew">更新</v-btn>
    </v-card-actions>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import CorporationField from "../../components/common/fields/CorporationField.vue";
import CorporationTypes from "../../consts/CorporationTypes";
import { statuses as ApiStatus } from "../../libs/api-client";
import SupplierField from "../../components/common/fields/SupplierField.vue";
import CustomerField from "../../components/common/fields/CustomerField.vue";
import DeliveryStatus from "../../consts/DeliveryStatus";
import SlipIssuanceType from "../../consts/SlipIssuanceType";
import Validation from "../../libs/validation";

export default {
  name: "DeliveryDestinationEntry",
  props: ["inputModel"],
  components: {
    CorporationField,
    SupplierField,
    CustomerField,
  },
  data() {
    return {
      constants: {
        cafereoActions: ["C070402", "C070403"],
      },
      updateModel: null,
      validEntryForm: null,
      deliveryStatus: DeliveryStatus.all(),
      slipIssuanceType: SlipIssuanceType.all(),
      errors: "",
      FTCorpName: "エフトランス株式会社",
      rules: {
        required: Validation.required,
        maxLength: Validation.maxLength,
        phoneNo: Validation.phoneNo,
        mailAddress: Validation.mailAddress,
        zipCode: Validation.isZipcode,
        maxByteLengthSjis: Validation.maxByteLengthSjis,
        invoiceAll: (value) => !this.allowedAction(this.constants.cafereoActions) || Validation.required(value),
      },
      cafereoCorporationType: CorporationTypes.CAFEREO,
      makerCorporationType: CorporationTypes.MAKER,
      vendorCorporationType: CorporationTypes.VENDOR,
      corporationType: null,
    };
  },
  beforeMount() {
    this.init(this.inputModel);
  },
  watch: {
    inputModel(inputModel) {
      this.init(inputModel);
    },
    "updateModel.corporationCd"() {
      if (this.allowedAction(this.constants.cafereoActions)) {
        if (this.$refs?.corporations?.current != null) {
          this.corporationType = this.$refs.corporations.current?.corporationType;
        }
      }
    },
    "updateModel.transferWarehouses"(value) {
      if (value) {
        this.updateModel.delivery1 = this.FTCorpName;
      } else {
        if (this.updateModel.delivery1 == this.FTCorpName) {
          this.updateModel.delivery1 = "";
        }
      }
    },
    async "updateModel.deliveryZipCode"(value) {
      this.errors = await this.validDeliveryZipArea(value);
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
    vendorDisabledRule() {
      return this.isVendorUser;
    },
  },
  methods: {
    async init(inputModel) {
      if (this.$refs.deliveryForm) {
        this.$refs.deliveryForm.resetValidation();
      }
      if (this.$refs.corporations?.current) {
        this.$refs.corporations.current = null;
      }
      await this.initUpdateModel(inputModel);
    },
    reset() {
      this.corporationType = null;
      this.updateModel = {
        createNew: true,
        corporationCd: null,
        deliveryCode: null,
        deliveryStatus: null,
        groupCode: null,
        groupName: null,
        deliveryZipCode: null,
        delivery1: null,
        delivery2: null,
        deliveryAddress: null,
        deliveryPhoneNumber: null,
        slipIssuance_type: null,
        routeDelivery: null,
        transferWarehouses: null,
        updateDatetime: null,
      };
    },
    initUpdateModel(inputModel) {
      this.corporationType = this.allowedAction(this.constants.cafereoActions)
        ? inputModel.corporationType
        : CorporationTypes.VENDOR;
      this.updateModel = {
        createNew: inputModel.createNew,
        corporationCd: inputModel.corporationCd,
        deliveryCode: inputModel.deliveryCode,
        deliveryStatus: inputModel.deliveryStatus,
        groupCode: inputModel.groupCode,
        groupName: inputModel.groupName,
        deliveryZipCode: inputModel.deliveryZipCode,
        delivery1: inputModel.delivery1,
        delivery2: inputModel.delivery2,
        deliveryAddress: inputModel.deliveryAddress,
        deliveryPhoneNumber: inputModel.deliveryPhoneNumber,
        slipIssuance_type: inputModel.slipIssuance_type,
        routeDelivery: inputModel.routeDelivery,
        transferWarehouses: inputModel.transferWarehouses,
        updateDatetime: inputModel.updateDatetime,
      };
    },
    async onUpdateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("deliveryDestination/update", { delivery: this.updateModel });
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.reset();
              this.$emit("onEditSubmit", result.data.contents.delivery);
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
              this.$dialog.warning({
                title: `配送先編集`,
                text: result.data.header.messages[this.updateModel.deliveryCode].join(),
                actions: ["OK"],
              });
              break;
            default:
              this.redirectError();
              break;
          }
        } catch (error) {
          console.error("DeliveryDestinationUpdate::onUpdateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },
    async onCreateClick() {
      if (this.validate()) {
        try {
          this.loadingOn();
          const result = await this.$store.dispatch("deliveryDestination/create", { delivery: this.updateModel });
          if (ApiStatus.isSystemError(result.data?.header)) {
            return this.redirectError();
          }
          this.loadingOff();
          this.corporationType = null;
          this.$emit("onEntrySubmit", result.data.contents.delivery);
          this.reset();
        } catch (error) {
          console.error("DeliveryDestinationUpdate::onCreateClick", error);
          this.apiRequestError(error);
        } finally {
          this.loadingOff();
        }
      }
    },

    validate() {
      let isValid = this.$refs.deliveryForm.validate();
      if (!isValid || this.errors !== "") {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        isValid = false;
      }
      return isValid;
    },

    async validDeliveryZipArea(value) {
      if (!value) return "";
      let res = await this.$store.dispatch("deliveryArea/isZipCode", {
        zipCode: value,
      });

      if (!res.data.contents.isZipCode) {
        return "入力された郵便番号がありません。";
      }
      return "";
    },
    onClose() {
      this.reset();
      this.$emit("onDialogClose");
    },
  },
};
</script>
