<template>
  <v-autocomplete
    :label="label"
    :value="current"
    :items="candidate"
    :loading="loading"
    :filter="inputFilter"
    item-value="customerCd"
    item-text="customerName"
    :disabled="fieldDisabled"
    :rules="rules"
    @change="onSelected"
    clearable
    hide-no-data
    filled
    dense
    :error-messages="fieldErrorMessages"
    :attach="attach"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "CustomerField",
  model: { prop: "code", event: "selected" },
  props: {
    label: { type: String, default: "取引先名" },
    code: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    attach: { type: Boolean, default: true },
    corporate: { type: String, default: null },
    disabledNoData: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    errorMessages: { type: Array, default: () => [] },
  },
  data() {
    return {
      loading: null,
      candidate: [],
      current: null,
      inputFilter: (item, queryText) => item.customerCd.includes(queryText) || item.customerName.includes(queryText),
    };
  },
  async mounted() {
    await this.initCustomers(this.corporate, this.code);
  },
  watch: {
    async corporate(corporationCd) {
      await this.initCustomers(corporationCd, this.code);
    },
    async code(value) {
      if (value) {
        if (this.current == null || this.current.customerCd !== value) {
          await this.initCustomers(this.corporate, value);
        }
      } else {
        this.current = null;
      }
    },
  },
  computed: {
    fieldDisabled() {
      return this.disabled || (this.disabledNoData && this.candidate.length === 0);
    },
    fieldErrorMessages() {
      return this.errorMessages;
    },
  },
  methods: {
    onSelected(value) {
      this.current = this.candidate.find((c) => c.customerCd === value);
      this.$emit("selected", value);
    },
    selected() {
      return this.current;
    },
    async initCustomers(corporationCd, customerCd) {
      try {
        if (corporationCd && corporationCd.length > 0) {
          this.loading = true;
          const params = { corporationCd };
          const response = await this.$store.dispatch("customer/suggest", params);
          this.candidate = response.data.contents.customers;
        } else {
          this.candidate = [];
        }
        if (this.candidate.length === 0) {
          this.current = null;
          this.$emit("selected", null);
        } else {
          this.current = this.candidate.find((c) => c.customerCd === customerCd);
          this.$emit("selected", this.current ? this.current.customerCd : null);
        }
      } catch (error) {
        console.error("CustomerField::initCustomers", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
