<template>
  <v-autocomplete
    :label="label"
    :value="current"
    :items="candidate"
    :loading="loading"
    :filter="inputFilter"
    :item-text="(item) => item.delivery1 + ' ' + item.delivery2"
    item-value="deliveryCode"
    :disabled="fieldDisabled"
    @change="onSelected"
    clearable
    hide-no-data
    filled
    dense
    :rules="rules"
    :attach="attach"
    :error-messages="fieldErrorMessages"
  ></v-autocomplete>
</template>

<script>
export default {
  name: "DeliveryField",
  model: { prop: "code", event: "selected" },
  props: {
    label: { type: String, default: "配送先名" },
    code: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    attach: { type: Boolean, default: true },
    groupType: { type: Number, default: null },
    groupCode: { type: String, default: null },
    disabledNoData: { type: Boolean, default: false },
    rules: { type: Array, default: () => [] },
    errorMessages: { type: Array, default: () => [] },
  },
  data() {
    return {
      loading: null,
      candidate: [],
      current: null,
      inputFilter: (item, queryText) =>
        item.deliveryCode.includes(queryText) || (item.delivery1 + " " + item.delivery2).includes(queryText),
    };
  },
  async mounted() {
    await this.initDeliverys(this.groupCode, this.code);
  },
  watch: {
    async groupCode(groupCode) {
      await this.initDeliverys(groupCode, this.code);
    },
    async code(value) {
      if (value) {
        if (this.current == null || this.current.deliveryCode !== value) {
          await this.initDeliverys(this.groupCode, value);
        }
      } else {
        this.current = null;
      }
    },
  },
  computed: {
    fieldDisabled() {
      return this.disabled || (this.disabledNoData && this.candidate.length === 0);
    },
    fieldErrorMessages() {
      return this.errorMessages;
    },
  },
  methods: {
    onSelected(value) {
      this.current = this.candidate.find((c) => c.deliveryCode === value);
      this.$emit("selected", value);
    },
    selected() {
      return this.current;
    },
    async initDeliverys(groupCode, deliveryCode) {
      try {
        if (groupCode && groupCode.length > 0) {
          this.loading = true;
          let response = await this.$store.dispatch("deliveryDestination/searchDelivery", { groupCode: groupCode });
          if (response.data?.contents?.deliveries) {
            this.candidate = response.data.contents.deliveries;
          } else {
            this.candidate = [];
          }
        } else {
          this.candidate = [];
        }
        if (this.candidate.length === 0) {
          this.current = null;
          this.$emit("selected", null);
        } else {
          this.current = this.candidate.find((c) => c.deliveryCode === deliveryCode);
          this.$emit("selected", this.current ? this.current.deliveryCode : null);
        }
      } catch (error) {
        console.error("DeliveryField::initDeliverys", error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
