<template>
  <v-container fluid v-if="details">
    <v-row no-gutters>
      <v-col v-for="detail in details" :key="detail.name" cols="12" xs="12">
        <detail-card v-if="detail.name" :name="detail.name" :value="detail.value"></detail-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DetailCard from "../common/DetailCard.vue";

export default {
  name: "CustomerDetals",
  props: ["details"],
  components: {
    DetailCard,
  },
};
</script>
