<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<template>
  <v-container fluid style="height: 100%">
    <v-row>
      <v-app-bar dense>
        <v-app-bar-title><v-icon>mdi-text-box-search</v-icon>仕入先一覧</v-app-bar-title>
        <v-spacer></v-spacer>
        <error-grid-dialog ref="errorGrid" width="1100px" height="350px"></error-grid-dialog>
        <v-form ref="searchForm" v-model="validSearchForm" lazy-validation>
          <search-conditions @search="onBtnSearch" max-height="100%">
            <v-text-field v-model="searchModel.supplierName" label="仕入先名" dense></v-text-field>
            <dp-date-picker
              v-model="searchModel.createDateFrom"
              type="date"
              :rules="[rules.createDateFrom]"
              label="登録日付From"
              dense
            ></dp-date-picker>
            <dp-date-picker
              v-model="searchModel.createDateTo"
              type="date"
              label="登録日付To"
              :rules="[rules.createDateTo]"
              dense
            ></dp-date-picker>
          </search-conditions>
        </v-form>
        <tooltip-icon-button icon="mdi-refresh" @click="onBtnSearch">リフレッシュ</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-button icon="mdi-pencil-plus-outline" @click="onBtnInsert" v-if="allowedAction('C070502')"
          >新規登録</tooltip-icon-button
        >
        <v-divider vertical></v-divider>
        <tooltip-icon-button
          :disabled="!selectedRow"
          icon="mdi-pencil-outline"
          @click="onBtnUpdate"
          v-if="allowedAction('C070503')"
          >編集</tooltip-icon-button
        >
        <v-dialog v-model="editDialog.show" :max-width="editDialog.width" persistent scrollable>
          <supplier-entry
            ref="supplierEntry"
            :inputModel="supplierModel"
            @onDialogClose="onDialogClose"
            @onEntrySubmit="onEntrySubmit"
            @onEditSubmit="onEditSubmit"
            v-if="editDialog.show"
          ></supplier-entry>
        </v-dialog>
        <tooltip-icon-button
          :disabled="selectionRows.length === 0"
          icon="mdi-trash-can-outline"
          @click="onBtnDelete"
          v-if="allowedAction('C070504')"
          >削除</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-download" @click="onBtnExport" v-if="allowedAction('C070505')"
          >CSVダウンロード</tooltip-icon-button
        >
        <tooltip-icon-button icon="mdi-filter-off" @click="clearFilters()">フィルター解除</tooltip-icon-button>
        <v-divider vertical></v-divider>
        <tooltip-icon-toggle-button icon="mdi-information-outline" v-model="detailSelected"
          >詳細表示</tooltip-icon-toggle-button
        >
      </v-app-bar>
    </v-row>

    <v-row style="height: 100%">
      <v-col
        :style="gridStyle"
        :cols="detailSelected && !infoMaximum ? 9 : 12"
        v-show="!detailSelected || !infoMaximum"
      >
        <ag-grid-vue
          id="SupplierList"
          class="ag-theme-alpine"
          style="height: 100%"
          :defaultColDef="defaultColDef"
          :columnDefs="columnDefs"
          :rowData="rowData"
          :alwaysShowHorizontalScroll="true"
          :pagination="true"
          :paginationPageSize="selectedPageSize"
          :suppressCellSelection="true"
          :enableCellTextSelection="true"
          :suppressCsvExport="false"
          :suppressExcelExport="true"
          :defaultCsvExportParams="defaultCsvExportParams"
          :localeText="localeText"
          :columnTypes="columnTypes"
          :frameworkComponents="frameworkComponents"
          rowSelection="multiple"
          @grid-ready="onGridReady"
          @selection-changed="onSelectionChanged"
          :getRowNodeId="(data) => data.supplierCode"
        >
        </ag-grid-vue>
      </v-col>
      <v-col
        v-if="detailSelected"
        id="SupplierInfos"
        style="flex-basis: auto; display: flex"
        :cols="infoMaximum ? 12 : 3"
      >
        <v-divider vertical></v-divider>
        <supplier-infos
          v-on:infoMaximum-event="infoMaximum = !infoMaximum"
          :details="selectedDetails"
          :supplierCode="selectedRow != null ? selectedRow.supplierCode : null"
        ></supplier-infos>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import moment from "moment";
import { getDisplayDetails2, typeFormat } from "../../models/ag-grid/helpers";
import { SelectionFilter } from "../../components/ag-grid/filters";
import { FullDateColumn, DateColumn } from "../../models/ag-grid/columnTypes";
import AccountTypes from "../../consts/AccountTypes";
import SupplierInfos from "../../components/supplier/SupplierInfos.vue";
import SupplierEntry from "../../components/supplier/SupplierEntry.vue";
import SearchConditions from "../../components/common/SearchConditions.vue";
import TooltipIconButton from "../../components/common/TooltipIconButton.vue";
import TooltipIconToggleButton from "../../components/common/TooltipIconToggleButton.vue";
import SupplierStatus from "../../consts/SupplierStatus";
import { statuses as ApiStatus } from "../../libs/api-client";
import RoundingAttribute from "../../consts/RoundingAttribute";
import { VendorPaymentTermsClosingDate, MakerPaymentTermsClosingDate } from "../../consts/PaymentTermsClosingDate";
import { VendorPaymentTermsPaymentDate, MakerPaymentTermsPaymentDate } from "../../consts/PaymentTermsPaymentDate";
import ErrorGridDialog from "../../components/common/ErrorGridDialog.vue";

export default {
  name: "SupplierSearch",
  components: {
    AgGridVue,
    SupplierInfos,
    SupplierEntry,
    SearchConditions,
    TooltipIconButton,
    TooltipIconToggleButton,
    ErrorGridDialog,
  },
  data() {
    return {
      gridStyle: { height: "95%" },
      gridApi: null,
      columnApi: null,
      defaultColDef: {
        filter: "agTextColumnFilter",
        resizable: true,
        sortable: true,
        suppressSizeToFit: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rules: {
        createDateFrom: (value) => this.createDateFromRules(value),
        createDateTo: (value) => this.createDateToRules(value),
      },
      columnDefs: [
        {
          headerName: "",
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true,
          filter: false,
          resizable: false,
          sortable: false,
          pinned: "left",
        },
      ],
      validSearchForm: null,
      vpaymentTermsClosingDate: VendorPaymentTermsClosingDate.all(),
      vpaymentTermsPaymentDate: VendorPaymentTermsPaymentDate.all(),
      cafereoColumnDefs: [
        {
          headerName: "ステータス",
          field: "status",
          filter: "dpSelectionFilter",
          filterParams: { options: SupplierStatus.all() },
          valueGetter: (params) => SupplierStatus.of(params.data.status),
        },
        { headerName: "仕入担当", field: "stockStaffName" },
        { headerName: "アシスタント", field: "assistantName" },
        { headerName: "仕入先CD", field: "supplierCode" },
        { headerName: "仕入先名", field: "supplierName" },
        { headerName: "仕入先略称", field: "maker" },
        { headerName: "郵便番号", field: "postalCode" },
        { headerName: "住所", field: "streetAddress" },
        { headerName: "電話番号", field: "telNumber" },
        { headerName: "FAX番号", field: "faxNumber" },
        { headerName: "担当部署", field: "salesDepartment" },
        { headerName: "仕入先担当者", field: "supplierStaff" },
        {
          headerName: "締め日",
          colId: "deadline",
          filter: "dpSelectionFilter",
          filterParams: { options: MakerPaymentTermsClosingDate.all() },
          valueGetter: (params) => MakerPaymentTermsClosingDate.of(params.data.deadline),
        },
        {
          headerName: "支払日",
          colId: "paymentDate",
          filter: "dpSelectionFilter",
          filterParams: { options: MakerPaymentTermsPaymentDate.all() },
          valueGetter: (params) => MakerPaymentTermsPaymentDate.of(params.data.paymentDate),
        },
        { headerName: "取引銀行", field: "bank" },
        { headerName: "支店", field: "branch" },
        {
          headerName: "種別",
          colId: "accountType",
          filter: "dpSelectionFilter",
          filterParams: { options: AccountTypes.all() },
          valueGetter: (params) => AccountTypes.of(params.data.accountType),
        },
        { headerName: "口座番号", field: "accountNumber" },
        {
          headerName: "端数処理属性",
          colId: "roundingAttribute",
          filter: "dpSelectionFilter",
          filterParams: { options: RoundingAttribute.all() },
          valueGetter: (params) => RoundingAttribute.of(params.data.roundingAttribute),
        },
        { headerName: "登録番号", field: "invoiceRegistrationNumber" },
        { headerName: "備考", field: "remarks" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
        { headerName: "法人CD", field: "corporationCd", hide: "true" },
        { headerName: "法人名", field: "corporationName", hide: "true" },
      ],
      defaultCsvExportParams: {
        allColumns: false,
        onlySelectedAllPages: false,
        fileName: `仕入先一覧`,
      },
      detailSelected: false,
      rowsSelected: null,
      infoMaximum: false,
      selectedPageSize: null,
      localeText: AG_GRID_LOCALE_JA,
      columnTypes: {
        dpFullDateColumn: FullDateColumn,
        dpDateColumn: DateColumn,
      },
      frameworkComponents: {
        dpSelectionFilter: SelectionFilter,
      },
      rowData: [],
      selectionRows: [],
      searchModel: {
        supplierName: "",
        createDateFrom: "",
        createDateTo: "",
        pauseDisplayFlg: true,
      },
      editDialog: { show: false, width: "1800px" },
      supplierModel: {
        createNew: false,
        supplierCode: null,
        corporationCd: null,
        supplierName: null,
        postalCode: null,
        streetAddress: null,
        telNumber: null,
        faxNumber: null,
        supplierStaff: null,
        status: null,
        stockStaff: null,
        maker: null,
        salesDepartment: null,
        roundingAttribute: 2,
        deadline: null,
        paymentDate: null,
        bank: null,
        branch: null,
        accountType: null,
        accountNumber: null,
        assistant: null,
        remarks: null,
        updateDatetime: null,
      },
      detailColumnDefs: [
        { headerName: "仕入先CD", field: "supplierCode" },
        { headerName: "仕入先名", field: "supplierName" },
        { headerName: "法人CD", field: "corporationCd" },
        { headerName: "法人名", field: "corporationName" },
        { headerName: "郵便番号", field: "postalCode" },
        { headerName: "住所", field: "streetAddress" },
        { headerName: "電話番号", field: "telNumber" },
        { headerName: "FAX番号", field: "faxNumber" },
        { headerName: "仕入先担当者", field: "supplierStaff" },
        {
          headerName: "締め日",
          colId: "deadline",
          filter: "dpSelectionFilter",
          filterParams: { options: MakerPaymentTermsClosingDate.all() },
          valueGetter: (params) => MakerPaymentTermsClosingDate.of(params.data.deadline),
        },
        {
          headerName: "支払日",
          colId: "paymentDate",
          filter: "dpSelectionFilter",
          filterParams: { options: MakerPaymentTermsPaymentDate.all() },
          valueGetter: (params) => MakerPaymentTermsPaymentDate.of(params.data.paymentDate),
        },
        { headerName: "取引銀行", field: "bank" },
        { headerName: "支店", field: "branch" },
        {
          headerName: "種別",
          colId: "accountType",
          filter: "dpSelectionFilter",
          filterParams: { options: AccountTypes.all() },
          valueGetter: (params) => AccountTypes.of(params.data.accountType),
        },
        { headerName: "口座番号", field: "accountNumber" },
        {
          headerName: "ステータス",
          field: "status",
          filterParams: { options: SupplierStatus.all() },
          valueGetter: (params) => SupplierStatus.of(params.data.status),
        },
        { headerName: "担当部署", field: "salesDepartment" },
        { headerName: "仕入担当", field: "stockStaffName" },
        { headerName: "仕入先略称", field: "maker" },
        {
          headerName: "端数処理属性",
          colId: "roundingAttribute",
          filter: "dpSelectionFilter",
          filterParams: { options: RoundingAttribute.all() },
          valueGetter: (params) => RoundingAttribute.of(params.data.roundingAttribute),
        },
        { headerName: "登録番号", field: "invoiceRegistrationNumber" },
        { headerName: "備考", field: "remarks" },
        { headerName: "更新日時", field: "updateDatetime", type: "dpDateColumn" },
        { headerName: "更新者", field: "updateUser" },
        { headerName: "登録日時", field: "createDatetime", type: "dpDateColumn" },
        { headerName: "登録者", field: "createUser" },
      ],
    };
  },
  beforeMount() {
    this.selectedPageSize = this.globalPageSize;
    this.columnDefs = this.columnDefs.concat(this.cafereoColumnDefs);
  },
  async mounted() {
    try {
      this.loadingOn();
      this.onSearchClick();
      this.handleResize();
      this.gridStyle.height = this.gridHeightSize + "px";
      window.addEventListener("resize", this.handleResize);
    } catch (error) {
      console.error("SupplierSearch::mounted", error);
      this.loadingOff();
      this.apiRequestError(error);
    }
  },
  watch: {
    globalPageSize(size) {
      this.gridApi.paginationSetPageSize(size);
    },
    gridHeightSize(value) {
      this.gridStyle.height = value + "px";
    },
  },
  computed: {
    selectedRow() {
      return this.selectionRows.length === 1 ? this.selectionRows[0] : null;
    },
    selectedDetails() {
      if (!this.selectedRow) {
        return [];
      }

      return getDisplayDetails2(this.selectedRow.supplierCode, this.detailColumnDefs, this.gridApi);
    },
  },
  methods: {
    clearFilters() {
      this.gridApi.setFilterModel(null);
      this.columnApi.applyColumnState({
        defaultState: { sort: null },
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
    },
    onBtnExport() {
      var allColumnIds = [];
      this.columnApi.getAllGridColumns().forEach(function (column, idx) {
        if (idx > 0 && !column.colDef.hide) allColumnIds.push(column.colId);
      });
      this.gridApi.exportDataAsCsv({
        allColumns: false,
        onlySelected: this.selectionRows.length > 0,
        columnKeys: allColumnIds,
        processCellCallback: (params) => typeFormat(params.column.colDef?.type, params.value),
      });
    },
    onSelectionChanged() {
      this.selectionRows = this.gridApi.getSelectedRows();
      this.defaultCsvExportParams.onlySelectedAllPages = this.selectionRows.length > 0;
    },
    onBtnInsert() {
      this.supplierModel = {
        createNew: true,
        supplierCode: null,
        corporationCd: null,
        supplierName: null,
        postalCode: null,
        streetAddress: null,
        telNumber: null,
        faxNumber: null,
        supplierStaff: null,
        status: null,
        stockStaff: null,
        maker: null,
        salesDepartment: null,
        roundingAttribute: 2,
        deadline: null,
        paymentDate: null,
        bank: null,
        branch: null,
        accountType: null,
        accountNumber: null,
        assistant: null,
        updateDatetime: null,
        invoiceRegistrationNumber: null,
        remarks: null,
      };
      this.editDialog.show = true;
    },
    onBtnUpdate() {
      this.supplierModel = {
        createNew: false,
        supplierCode: this.selectedRow.supplierCode,
        corporationCd: this.selectedRow.corporationCd,
        supplierName: this.selectedRow.supplierName,
        postalCode: this.selectedRow.postalCode,
        streetAddress: this.selectedRow.streetAddress,
        telNumber: this.selectedRow.telNumber,
        faxNumber: this.selectedRow.faxNumber,
        supplierStaff: this.selectedRow.supplierStaff,
        status: this.selectedRow.status,
        stockStaff: this.selectedRow.stockStaff,
        maker: this.selectedRow.maker,
        salesDepartment: this.selectedRow.salesDepartment,
        roundingAttribute: this.selectedRow.roundingAttribute,
        deadline: this.selectedRow.deadline,
        paymentDate: this.selectedRow.paymentDate,
        bank: this.selectedRow.bank,
        branch: this.selectedRow.branch,
        accountType: this.selectedRow.accountType,
        accountNumber: this.selectedRow.accountNumber,
        assistant: this.selectedRow.assistant,
        invoiceRegistrationNumber: this.selectedRow.invoiceRegistrationNumber,
        remarks: this.selectedRow.remarks,
        updateDatetime: this.selectedRow.updateDatetime,
      };
      this.editDialog.show = true;
    },
    async onBtnDelete() {
      try {
        const messageText = `選択された仕入先情報を削除します <small>(${this.selectionRows.length}件)</small>`;
        const ok = await this.$dialog.confirm({ title: `仕入先一覧`, text: messageText });
        if (ok) {
          const suppliers = {
            suppliers: this.selectionRows.map((r) => ({
              supplierCode: r.supplierCode,
              updateDate: r.updateDatetime,
            })),
          };

          const result = await this.$store.dispatch("supplier/remove", suppliers);
          let errorMsg = {};
          let tmpErrorMsg = {};
          switch (result.data?.header.resultCode) {
            case ApiStatus.consts.SUCCESS:
              this.gridApi.applyTransaction({ remove: this.selectionRows });
              this.$dialog.notify.info(`仕入先情報が削除されました (${this.selectionRows.length}件)`, {
                timeout: 2300,
              });
              this.onSearchClick();
              break;
            case ApiStatus.consts.ALREADY_CHANGED:
            case ApiStatus.consts.BUSINESS_ERROR:
              tmpErrorMsg = this.selectionRows.map(function (r) {
                let tmp = null;
                if (result.data.header.messages[r.supplierCode]) {
                  tmp = {
                    supplierCode: r.supplierCode,
                    errorMsg: result.data.header.messages[r.supplierCode]?.join(),
                  };
                }
                return tmp;
              });
              errorMsg = tmpErrorMsg.filter((r) => r != null);

              this.$refs.errorGrid.open({
                title: "エラーメッセージ",
                columns: [
                  {
                    headerName: "仕入先CD",
                    field: "supplierCode",
                    pinned: "left",
                  },
                  { headerName: "エラーメッセージ", field: "errorMsg" },
                ],
                records: errorMsg,
                onClose: async () => {
                  this.selectionRows = [];
                  this.onSearchClick();
                },
              });
              break;
            default:
              this.redirectError();
              break;
          }
        }
      } catch (error) {
        console.error("SupplierSearch::onBtnDelete", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onBtnSearch() {
      const isValid = this.$refs.searchForm.validate();
      if (!isValid) {
        this.$dialog.notify.error(`入力エラーがあります`, { timeout: 2300 });
        return;
      }
      this.$refs.searchForm.resetValidation();
      this.onSearchClick();
    },
    async onSearchClick() {
      try {
        this.loadingOn();
        this.selectionRows = [];
        const response = await this.$store.dispatch("supplier/search", this.searchModel);
        if (ApiStatus.isSystemError(response.data?.header)) {
          return this.redirectError();
        }

        const result = response.data.contents;
        if (result.over) {
          this.$dialog.warning({
            title: `仕入先一覧`,
            text: `検索上限数を超えました。結果は${result.limit}件まで表示されます。`,
            actions: ["OK"],
          });
        }
        if (Object.keys(result.suppliers).length === 0) {
          this.$dialog.warning({
            title: "仕入先一覧",
            text: `検索結果は0件です。`,
            actions: ["OK"],
          });
        }
        this.gridApi.setRowData(response.data.contents.suppliers);
        const allColumnIds = this.columnApi.getAllColumns().map((column) => column.colId);
        this.columnApi.autoSizeColumns(allColumnIds);
      } catch (error) {
        console.error("SupplierSearch::onSearchClick", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    onDialogClose() {
      this.editDialog.show = false;
    },
    async onEntrySubmit(updateModel) {
      try {
        this.loadingOn();
        this.editDialog.show = false;
        this.$dialog.notify.info(`仕入先情報を登録しました (${updateModel.supplierCode})`, { timeout: 2300 });
        this.onSearchClick();
      } catch (error) {
        console.error("SupplierSearch::onEntrySubmit", error);
        this.loadingOff();
        this.apiRequestError(error);
      }
    },
    async onEditSubmit(updateModel) {
      try {
        this.editDialog.show = false;
        this.$dialog.notify.info(`仕入先情報を更新しました (${updateModel.supplierCode})`, {
          timeout: 2300,
        });
        this.onSearchClick();
      } catch (error) {
        console.error("SupplierSearch::onEditSubmit", error);
        this.apiRequestError(error);
      } finally {
        this.loadingOff();
      }
    },
    createDateFromRules(value) {
      if (value == null || this.searchModel.createDateTo == null) {
        return true;
      }
      if (moment(new Date(value)).isAfter(this.searchModel.createDateTo)) {
        return "出荷予定日Toより前の日付を指定してください";
      }
      return true;
    },
    createDateToRules(value) {
      if (value == null || this.searchModel.createDateFrom == null) {
        return true;
      }
      if (moment(new Date(value)).isBefore(this.searchModel.createDateFrom)) {
        return "出荷予定日Fromより後の日付を指定してください";
      }
      return true;
    },
  },
};
</script>
