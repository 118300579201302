import { createDefault } from "../../utils/ConstUtils";

export default createDefault([
  { value: 1, text: "取引先名", enum: "CUSTOMER_NAME" },
  { value: 2, text: "郵便番号", enum: "ZIP_CODE" },
  { value: 3, text: "都道府県", enum: "PREFECTURE" },
  { value: 4, text: "所在地（住所）", enum: "LOCATION_ADDRESS" },
  { value: 5, text: "所在地（ビル名）", enum: "LOCATION_BUILDING" },
  { value: 6, text: "電話番号", enum: "TEL_NUMBER" },
  { value: 7, text: "FAX番号", enum: "FAX_NUMBER" },
  { value: 8, text: "担当者", enum: "CUSTOMER_STAFF" },
  { value: 9, text: "社内発注上限金額", enum: "IN_COMPANY_ORDER_MAX_AMOUNT" },
  { value: 10, text: "ステータス", enum: "CUSTOMER_STATUS" },
  // { value: 11, text: "支払条件締日", enum: "PAYMENT_TERMS_CLOSING_DATE" },
  // { value: 12, text: "支払条件支払日", enum: "PAYMENT_TERMS_PAYMENT_DATE" },
  // { value: 13, text: "取引開始年月日", enum: "TRANSACTION_START_DATE" },
  { value: 14, text: "出荷曜日指定", enum: "SHIPMENT_DAY_OF_WEEK" },
  { value: 15, text: "オリコン指定", enum: "USE_ORICON" },
  { value: 16, text: "フォーキャスト可否", enum: "FORECAST" },
  { value: 17, text: "自動出荷希望", enum: "AUTO_SHIP_REQUEST" },
  { value: 18, text: "備考", enum: "REMARKS" },
  { value: 19, text: "倉庫備考", enum: "WAREHOUSE_REMARKS" },
  { value: 20, text: "請求書発行可否", enum: "INVOICE_APPROVAL" },
  { value: 21, text: "請求先〒", enum: "INVOICE_ZIP_CODE" },
  { value: 22, text: "請求先住所", enum: "INVOICE_ADDRESS" },
  { value: 23, text: "請求先TEL", enum: "INVOICE_PHONE_NUMBER" },
  { value: 24, text: "請求担当者", enum: "INVOICE_STAFF" },
  { value: 25, text: "請求書宛名", enum: "INVOICE_MAILING_ADDRESS" },
  { value: 26, text: "営業担当", enum: "OUR_SALES_STAFF" },
  { value: 27, text: "担当AS", enum: "ASSISTANT" },
  { value: 28, text: "JAN表示", enum: "JAN_DISPLAY" },
]);
